export const fontFaces = `
  @font-face {
    font-family: 'FT System Blank';
    src: url('${process.env.PUBLIC_URL}/fonts/FTSystemBlank-Semibold.woff2') format('woff2'),
         url('${process.env.PUBLIC_URL}/fonts/FTSystemBlank-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'FT System Mono';
    src: url('${process.env.PUBLIC_URL}/fonts/FTSystemMono-Medium.woff2') format('woff2'),
         url('${process.env.PUBLIC_URL}/fonts/FTSystemMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
`;